// Packages
import { Routes, Route } from "react-router-dom"


// Screens
import HomeScreen from "modules/Account/screens/home"



const AccountRoutes = () => 
{
	return (
		
		<Routes>
			<Route path="/" element={<HomeScreen auth={true}/>}/>
		</Routes>
	)
}

export default AccountRoutes;
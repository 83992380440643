// Packages
import { useEffect } from 'react'
import { useNavigate, useSearchParams } from 'react-router-dom'


const AppLoginScreen = () => {

    const navigate = useNavigate();
    const [searchParams, ] = useSearchParams();


    useEffect(() => {

        if ( searchParams.get('return') )
        {
            localStorage.setItem('forwardUrl', searchParams.get('return'))

            navigate('/');
        }
        else
        {
            navigate('/')
        }

    }, [navigate, searchParams])

    


    return (<></>);

}

export default AppLoginScreen;
// Packages
import { useNavigate, Link } from "react-router-dom";

// Components

import Layout from 'layouts/Default';


// other
import './home.css';

import { useOakoraAuth } from 'context/OakoraAuth'
import { useTenant } from 'context/Tenant'

import Config from 'config'

const HomeScreen = ({auth}) => {

    const { getBackgroundUrl, getLogoUrl, tenant } = useTenant();

    const { isAuthenticated, apps, user, redirectToLogin, logout } = useOakoraAuth();

    const navigate = useNavigate();

    if ( ! isAuthenticated )
    {
        redirectToLogin();
        return (<></>);
    }

    if ( localStorage.getItem('forwardUrl') )
    {
        let forwardUrl = localStorage.getItem('forwardUrl')
        localStorage.removeItem('forwardUrl')

        window.location = forwardUrl + '/auth/token?_token=' + localStorage.getItem(Config.TOKEN_NAME)
    }

    return(
        <Layout bodyBackgroundUrl={getBackgroundUrl()}>

            <img src={getLogoUrl()} alt={tenant.name} className="logo"/>
        
                <h1 className="LoginScreen__form__title">Hello, {user.firstname} {user.lastname}</h1>

                <p>You are a <b>{user.meta.rank.name}</b> from <b>{tenant.name}</b></p>     

                <p>ID: {user.external_id}</p>        

                <br/>

                {apps.map((app) => {

                    if ( app.enabled !== true )
                    {
                        return (<></>)
                    }

                    return (
                        <div style={{padding: '5px 0'}}>
                            <Link to={`https://${app.tenant.domain}`}>&rarr;{app.tenant.name}</Link>
                        </div>
                    )

                })}
                <br/>


               <button onClick={async () => {

                await logout();
                navigate('/auth');
               }}>Log out</button>


        </Layout>
    )
}

export default HomeScreen;
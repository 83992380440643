
import './styles.css'


const DefaultLayout = props => {

	const { bodyBackgroundUrl } = props

	return (
		<div className="DefaultLayout" style={{
			backgroundImage: "url("+bodyBackgroundUrl+")"
		}}>

		
			<div className="DefaultLayout__canvas">
			{props.children}
			</div>

		</div>
	)
}

export default DefaultLayout
// Packages
import { useState, useEffect } from "react";
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import md5 from 'md5';

// Components
import Button from "components/Button";
import BTDTextField from "components/BTDUI/fields/BTDTextField";
import { useTenant } from 'context/Tenant'
import { useOakoraAuth } from 'context/OakoraAuth'

import Layout from 'layouts/Default';

// other
import './styles.css';
import emailIcon from 'assets/email-green.svg';

import Config from 'config';


const LoginScreen = () => {

    const { getLogoUrl, getBackgroundUrl, tenant } = useTenant();
    const { refresh: refreshAuth } = useOakoraAuth();

    const [email, setEmail] = useState('');
    const [isLoading, setLoading] = useState();
    const [error, setError] = useState()



    const [verificationToken, setVerificationToken] = useState();
    const [accessToken, setAccessToken] = useState(null);

    const [code, setCode] = useState('')


    const navigate = useNavigate();

    useEffect(() => {

        const refreshAndRedirect = async () => {

            if ( accessToken !== null )
            {
                await refreshAuth();
                navigate('/'); 
            }
        }

        refreshAndRedirect();

    }, [accessToken, refreshAuth, navigate])


    const requestToken = async () => {

        setLoading(true);
        setError();

        try 
        {
            const response = await axios.post(Config.API_URL + '/send-login-code', {
                email,
                tenant_id: tenant.id
            })
                
            setVerificationToken(response.data.token);
        } 
        catch (error) 
        {
            setError(error.response.data);
        }
        finally
        {
            setLoading(false);
        }
    }


    const verifyCode = async () => {

        setLoading(true);
        setError();


        let exchange = md5(code+verificationToken);

        try 
        {
            const response = await axios.post(Config.API_URL + '/verify-login-code', {
                code: exchange
            })

            localStorage.setItem(Config.TOKEN_NAME, response.data.access_token)
            setAccessToken(response.data.access_token);
        } 
        catch (error) 
        {
            setError("Verification of your login code has failed");
        }
        finally
        {
            setLoading(false);
        }
    }


    return(
        <Layout bodyBackgroundUrl={getBackgroundUrl()}>

            <img src={getLogoUrl()} alt={tenant.name} className="logo"/>

   
                
            { verificationToken &&
                <div className="check-email">
                    <img src={emailIcon} alt="Check your email" className="check-email__icon"/>
                    Check your email for your login code
                </div>
            }

            { ! verificationToken &&
                <>
                    
                <BTDTextField
                    error={error?.errors?.email}
                    label="Enter your email address"
                    onChange={(value) => {setEmail(value)}}
                    style={{margin: '0 auto 40px auto'}}
                    value={email ?? ''}
                />


                <Button
                   loading={isLoading}
                    label="Continue >"
                    size="large"
                    onClick={(e) => {
                        e.preventDefault();
                        requestToken({
                            email: email, 
                            tenant_id: 1
                        })
                    }}
                />
                </>
            }

            { verificationToken  &&
                <div className="codeinput">
                    <BTDTextField
                        label="Enter your login code"
                        onChange={(value) => {
                            setCode(value.replace(/\s/, ''))
                        }}
                        value={code ?? ''}
                        error={error}
                    />

                    <Button
                    label="Verify login code >"
                    size="large"
                    loading={isLoading}
                    onClick={(e) => {
                        e.preventDefault();
                        verifyCode();
                    }}
                />
                </div>

            }
        </Layout>
            
    )
}

export default LoginScreen;
// Packages
import { createContext, useContext, useState, useEffect } from 'react';
import axios from 'axios'

// Components
import Loading from 'components/Loading';

import Config from 'config';

const TenantContext = createContext();

export const TenantProvider = ({ children }) => {

    const [isLoading, setLoading] = useState(true);
    const [tenant, setTenant] = useState();
    const [settings, setSettings] = useState()
    const [, setError] = useState();


    useEffect(() => {

        document.title = settings?.product_name ? settings.product_name : ''

    }, [settings])



    useEffect(() => {

        axios.get(Config.API_URL + '/public/domains/' + window.location.hostname)
            .then(function (response) 
            {
                setTenant(response.data.data.tenant);
                setSettings(response.data.data.settings);
            })
            .catch(function (error) 
            {
                setError(error.response.data.errors);
            })
            .finally(function () 
            {
                setLoading(false)
            });

    }, [])


    const getLogoUrl = () => {
        return settings.login_logo_url;
    }

    const getBackgroundUrl = () => {
        return settings.login_background_url;
    }

    if ( isLoading )
    {
        return <Loading/>
    }

    return(

    <TenantContext.Provider value ={{
        tenant,
        getLogoUrl,
        getBackgroundUrl
    }}>
        {children}
    </TenantContext.Provider>
    )
}


export const useTenant = () => {
    return useContext(TenantContext);
};
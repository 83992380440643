
import spinner from './loading.svg'

import './styles.css'

const Button = props => {

	const { label, loading, onClick } = props

	if ( loading )
	{
		return (
			<div className={`Button Button--loading-${loading}`}>
				<img src={spinner} className="Button__Spinner" alt="Loading"/>
			</div>
		)
	}

	return (
		<div onClick={onClick} className={`Button Button--loading-${loading}`}>
			{label}
		</div>
	)
}

Button.defaultProps = {
	loading: false,
	onClick: () => { }
}

export default Button
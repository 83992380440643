// Packages
import { createContext, useState, useEffect, useContext } from 'react';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import Loading from 'components/Loading';


// Other
import Config from 'config';


const OakoraAuthContext = createContext();

export const OakoraAuthProvider = (props) => {

    const { loginUrl } = props;

    const [user, setUser] = useState();
    const [apps, setApps] = useState();
    const [isLoading, setLoading] = useState(true);
    const [isAuthenticated, setAuthenticated] = useState(false);
    const [errors, setErrors] = useState();
    const [refreshAuth, setRefreshAuth] = useState();


    const navigate = useNavigate();

    useEffect(() => 
    {
        if ( localStorage.getItem(Config.TOKEN_NAME) )
        {
            axios.defaults.headers.common['Authorization'] = 'Bearer ' + localStorage.getItem(Config.TOKEN_NAME);

            setLoading(true);
            setErrors(false);

            axios.get(Config.API_URL + '/appdata')
            .then(function (response) 
            {
                setUser(response.data.data.user);
                setApps(response.data.data.apps);
                setAuthenticated(true);
            })
            .catch(function (error) 
            {
                setErrors(error.response.data.errors);
            })
            .finally(function () 
            {
                setLoading(false)
            });
        }
        else
        {
            setLoading(false)
        }
    }, [refreshAuth])


    const refresh = () => {
        setRefreshAuth(!refreshAuth)
    }
    

    const redirectToLogin = () => {
        navigate(loginUrl)
    }


    const logout = () => {

        setErrors(false);

        axios.delete(Config.API_URL + '/logout')
        .then(function (response) 
        {
            localStorage.removeItem(Config.TOKEN_NAME);
            setUser();
            setAuthenticated(false);
        })
        .catch(function (error) 
        {
            setErrors(error.response.data.errors);
        })
        .finally(function () 
        {
            setLoading(false);
        });
        
    }



    if ( isLoading )
    {
        return (
            <Loading/>
        )
    }

    

    if ( ! loginUrl )
    {
        return (
            <>OakoraAuth: loginUrl is required on OakoraAuthProvider</>
        );
    }


    return(
        <OakoraAuthContext.Provider value={{
            user,
            errors,
            isLoading,
            isAuthenticated,
            redirectToLogin,
            logout,
            refresh,
            apps
        }}>
            { ! isLoading &&
                props.children
            }
        </OakoraAuthContext.Provider>
    )
}


export const useOakoraAuth = () => {
    return useContext(OakoraAuthContext);
}
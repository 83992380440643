// Packages
import { Routes, Route } from "react-router-dom"

// Screens
import LoginScreen from "../screens/login"
import AppLoginScreen from "../screens/app"


const LoginRoutes = () => 
{
	return (
		<Routes>
			<Route path="/" element={<LoginScreen />}/>
			<Route path="/app" element={<AppLoginScreen />}/>
		</Routes>
	)
}

export default LoginRoutes;

import './styles.css'

const Loading = props => {


	return (
		<div className="Loading">

			

		</div>
	)
}

export default Loading
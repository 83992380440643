// Packages
import { Routes, Route } from "react-router-dom"

// Routes
import AuthRoutes from "modules/Auth/routes"
import AccountRoutes from "modules/Account/routes"


const MainRoutes = () => 
{
	return (
		<Routes>
			<Route path="/auth/*" element={<AuthRoutes />}/>
			<Route path="/*" element={<AccountRoutes />}/>
		</Routes>
	)
}

export default MainRoutes
// Components
import Routes from 'routes';

// Other
import 'assets/styles.css';

function App() {

	return <Routes/>
}

export default App;
